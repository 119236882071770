import React from "react";
import "../../Styles/Administracao.css";

const Administracao = () => {
    

    return (
        <div>
            
        </div>
    );
};

export default Administracao;
