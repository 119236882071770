export const theme = {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryHover: '#343078',
    primaryPhones: '#DCDCDC',
    primaryMobiles: '#DCDCDC',
    primaryTablets: '#DCDCDC',
    primaryLaptops: '#DCDCDC',
    primaryLargeLaptops: '#DCDCDC', 
    phones: '600px', /* Extra small devices (phones, 600px and down) => max-with */
    mobiles: '600px', /* Small devices (portrait tablets and large phones, 600px and up) => min-width */
    tablets: '768px', /* Medium devices (landscape tablets, 768px and up) => min-width */
    laptops: '992px', /* Large devices (laptops/desktops, 992px and up) => min-width */
    largeLaptops: '1200px', /* Extra large devices (large laptops and desktops, 1200px and up) => min-width */

}