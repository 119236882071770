import React from "react";
import { Link } from "react-router-dom"; // Certifique-se de que você está usando react-router-dom para navegação
import "../../Styles/home.css"; // Importe o arquivo CSS para estilização

const Home = () => {
    return (
        
        <div className="home-container">
           
            <h1>Bem-vindo, bem vinda às Memórias da Família Gouveia</h1>
            <p>Explore as nossas preciosas memórias e reviva momentos especiais.</p>
            <Link to="/fotografias" className="link-button">
                Memórias
            </Link>
        </div>
    );
};

export default Home;
